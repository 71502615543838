import axios from 'axios'

export class Professor {
  static async obtemTodos () {
    return axios.get('/professores/todos')
  }
  static async obtemTodosAnoLetivo (ano) {
    try {
      return axios.get('/professores/ano?ano='+ano);
    }
    catch(e) {
      // console.log(e);
    }
  }
  static async obtemUm (id) {
    return axios.get('/professores/'+id)
  }
  static async cadastrar (payload) {
    return axios.post('/professores', payload)
  }
  static async alterar (payload) {
    return axios.put('/professores/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('/professores/'+id)
  }
  static async obtemTodasTurmasProfessor (professor,ano) {
    return axios.get('/turmas/professor/'+professor+'/'+ano)
  }
  static async obtemTodasTurmasProfessorOrganizado (professor,ano) {
    return axios.get('/turmas/professor/organizado/'+professor+'/'+ano)
  }
  static async obtemTodasTurmasProfessorOrganizadoTabela (professor,ano) {
    return axios.get('/turmas/professor/organizado/tabela/'+professor+'/'+ano)
  }
  static async buscarHorarioDeTrabalhoDeTodosProfessores () {
    return axios.get('/turmas/professor');
  }

  static async verTurnosdoProfessor (payload) {
    return axios.get('/professor/horario/'+payload.servidor_id+'/'+payload.escola_id+'/'+payload.ano)
  }
  static async editarTurnosProfessor (payload) {
    return axios.put('/professor/horario/'+payload.id, payload)
  }
  static async removerTurnoProfessor (id) {
    return axios.delete('/professor/horario/'+id)
  }

  static async addTurnoProfessor (payload) {
    return axios.post('/professor/horario', payload)
  }
  static async buscarTurnosProfessores (ano, servidor_id) {
    return axios.get('/professor/horario?servidor_id='+servidor_id+'&ano='+ano)
  }
  static async buscarTodosProfessoresDeUmaDisciplina (segmento, disciplina) {
    return axios.get('/professores/segimento/'+segmento+'/disciplina/'+disciplina);
  }

  static async buscarTodosProfessoresDeUmaDisciplina (payload) {
    return axios.get('/servidores/especialidade/'+payload.servidor_id+'/'+payload.segmento_id);
  }

  static async obterProfessorIDServidor (id_servidor) {
    return axios.get('/professores/servidor/'+id_servidor)
  }

  static async ServidorVerificarHorarios (id_servidor) {
    return axios.get('/verificar/horarios/'+id_servidor)
  }

}
